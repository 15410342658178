<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'bad-heilbronn',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/bad-heilbronn',
      imageCount: 7,
      imageFileFormat: '.jpg',
      name: 'Bad Fußbodenerneuerung nähe Heilbronn',
      description: 'Im Badezimmer eines Einfamilienhauses in der Nähe von Heilbronn wurde der Bodenaufbau erneuert, der Duschbereich umgebaut, eine elektrische Fußbodenheizung eingebaut und Großformatfliesen verlegt.',
    },
    //
  }),
};
</script>
